<template>
  <b-modal
    id="modalbasic"
    v-b-modal.modal-center
    ref="modalbasic"
    @hide="close()"
    v-model="isVisible"
    :title="this.title"
    @cancel="close()"
    @close="close()"
    :no-close-on-backdrop="true"
  >
    <Messages />
    <b-row>
      <b-col>
        <b-card sm="12">
          <Confirmation ref="Confirmation"></Confirmation>
          <b-form>
            <!-- Follow Up ID -->
            <b-row>
              <b-col>
                <p>Follow Up ID: {{ this.followUp.followUpID }}</p>
              </b-col>
            </b-row>
            <!-- End of Subject -->
            <!-- Subject -->
            <b-row>
              <b-col>
                <b-form-group label="Subject" class="lg">
                  <b-form-input
                    disabled
                    id="subject"
                    placeholder="Subject"
                    class="col-sm-10"
                    v-model="followUp.subject"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- End of Subject -->
            <!--Notes-->
            <b-row>
              <b-col>
                <b-form-group label="Notes" class="lg">
                  <b-form-textarea
                    disabled
                    id="notes"
                    placeholder="Notes"
                    class="col-sm-10"
                    rows="3"
                    v-model.trim="followUp.notes"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
            <!--End of Notes-->
            <!--Company Contacts-->
            <b-row>
              <b-col>
                <b-form-group label="Company Contacts" class="lg">
                  <b-form-input
                    disabled
                    id="contacts"
                    placeholder="Contacts"
                    class="col-sm-10"
                    v-model="followUp.contacts"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <!--End of Company Contacts-->
            <!--Due Date-->
            <b-row>
              <b-colxx lg="6">
                <b-form-group label="Due Date" class="lg">
                  <b-form-input
                    disabled
                    id="dueDate"
                    type="date"
                    class="col-sm-10"
                    v-model="followUp.dueDate"
                  />
                </b-form-group>
              </b-colxx>
            </b-row>
            <!--End of Due Date-->
            <!--Created Date-->
            <b-row>
              <b-colxx lg="6">
                <b-form-group label="Created Date" class="lg">
                  <b-form-input
                    disabled
                    id="dueDate"
                    type="date"
                    class="col-sm-10"
                    v-model="followUp.createdDate"
                  />
                </b-form-group>
              </b-colxx>
            </b-row>
            <!--End of Created Date-->
            <!--Created By-->
            <b-row>
              <b-col>
                <b-form-group label="Created By" class="lg">
                  <b-form-input
                    disabled
                    id="createdBy"
                    placeholder="Created By"
                    class="col-sm-10"
                    v-model="followUp.createdBy"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <!--End of Created By-->
            <!--Assigned To-->
            <b-row>
              <b-col>
                <b-form-group label="Assigned To" class="lg">
                  <b-form-input
                    disabled
                    id="assignedTo"
                    placeholder="assignedTo"
                    class="col-sm-10"
                    v-model="followUp.assignedTo"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <!--End of Assigned To-->
          </b-form>
        </b-card>
      </b-col>
    </b-row>
    <template #modal-footer="{ cancel }">
      <b-button
        size="lg"
        variant="outline-danger"
        class="mr-1"
        @click="(cancel())">
        Cancel
      </b-button>
      <div v-if="{ showCompleteButton }" style="display:flex;justify-content:center;align-items;center;">
        <b-button
          size="lg"
          v-b-modal.modal-test
          v-b-tooltip.hover.left
          :title="`Complete`"
          @click="completeFollowUp()"
        >Complete
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<style lang="scss" scoped>
  .is-invalid {
    border: 1px solid #dc3545 !important;
  }
  .alignWithLabel{
    width: 100%;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    margin-bottom: 0;
    line-height: 1.5;
  }
</style>

<script>
import { validationMixin } from 'vuelidate'
import Confirmation from '../Common/Confirmation.vue'
import FollowUpMixin from '../../mixins/FollowUpMixin.vue'
import CompanyContactMixin from '../../mixins/CompanyContactMixin.vue'
import moment from 'moment/moment.js'
import { mapMutations } from 'vuex'
import bus from '../../main'
import ModalMessages from '../../containers/navs/ModalMessages.vue'

const { required, maxLength } = require('vuelidate/lib/validators')

export default {
  name: 'followUpModal',
  components: {
    Confirmation: Confirmation,
    Messages: ModalMessages
  },

  mixins: [FollowUpMixin, validationMixin, CompanyContactMixin],
  validations: {
    followUp: {
      subject: {
        required
      },
      notes: {
        maxLength: maxLength(500)
      },
      dueDate: {
        required
      },
      companyContacts: {
        required
      },
      assignedTo: {
        required
      }
    }
  },
  data: () => ({
    title: undefined,
    isVisible: false,
    companyContactsSelected: [],
    assignedTo: []
  }),

  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setModalDangerMessage',
      'setModalInfoMessage'
    ]),
    async show (opts = {}) {
      this.title = opts.title

      if (opts.followUpID) {
        this.followUp.followUpID = opts.followUpID
        await this.getFollowUpForViewModalByID(opts.followUpID)
        this.followUp.dueDate = moment(this.followUp.dueDate).format('yyyy-MM-DD')
        this.followUp.completed = moment(this.followUp.completed).format('yyyy-MM-DD')
        this.followUp.createdDate = moment(this.followUp.createdDate).format('yyyy-MM-DD')
      }

      if (opts.companyID) {
        this.followUp.companyID = opts.companyID
      }

      if (opts.exitFunction) {
        this.exitFunction = opts.exitFunction
      }

      this.isVisible = true
    },
    close () {
      if (this.exitFunction) {
        this.exitFunction()
      }

      this.setModalDangerMessage('')
      this.setModalInfoMessage('')
      this.isVisible = false
    },
    cancel () {
      this.close()
    },

    showCompleteButton () {
      return !this.followUp.completed
    },

    async completeFollowUp () {
      bus.$emit('completeFollowUp', { followUpID: this.followUp.followUpID, companyID: this.followUp.companyID })
      this.close()
    }
  }
}
</script>
