<template>
  <b-modal
    id="modalbasic"
    v-b-modal.modal-center
    ref="modalbasic"
    @hide="close()"
    v-model="isVisible"
    :title="this.title"
    @cancel="close()"
    @close="close()"
    :no-close-on-backdrop="true"
  >
    <Messages />
    <b-row>
      <b-col>
        <b-card sm="12">
          <Confirmation ref="Confirmation"></Confirmation>
          <b-form>
            <!-- Subject -->
            <b-row>
              <b-col>
                <b-form-group label="Subject" class="lg">
                  <b-form-input
                    id="subject"
                    placeholder="Subject"
                    class="col-sm-6"
                    v-model.trim="$v.followUp.subject.$model"
                    :state="!$v.followUp.subject.$error"
                  >
                  </b-form-input>
                  <div
                    class="text-danger"
                    :state="!$v.followUp.subject.$error"
                    v-if="!$v.followUp.subject.required"
                  >
                  Subject is required!
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- End of Subject -->

            <!--Notes-->
            <b-row>
              <b-col>
                <b-form-group label="Notes" class="lg">
                  <b-form-textarea
                    id="notes"
                    placeholder="Notes"
                    class="col-sm-10"
                    rows="3"
                    v-model.trim="followUp.notes"
                    :state="!$v.followUp.notes.$error"
                  ></b-form-textarea>
                  <div
                    class="text-danger"
                    :state="!$v.followUp.notes.$error"
                    v-if="!$v.followUp.notes.maxLength"
                  >
                  Maximum Characters Allowed 500!
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <!--End of Notes-->

            <!--Company Contacts-->
            <b-row>
              <b-col>
                <b-form-group label="Company Contacts" class="lg">
                  <multiselect
                    id="companyContacts"
                    v-model="followUp.companyContacts"
                    :options="companyContacts"
                    :multiple="true"
                    :close-on-select="false"
                    placeholder="Select Contacts"
                    label="displayData"
                    track-by="contactID"
                  >
                  </multiselect>
                  <div
                    class="text-danger"
                    :state="!$v.followUp.companyContacts.$error"
                    v-if="!$v.followUp.companyContacts.required"
                  >
                  Contacts Required!
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <!--End of Company Contacts-->

            <!--Due Date-->
            <b-row>
              <b-colxx lg="6">
                <b-form-group label="Due Date" class="lg">
                  <b-form-input
                    id="dueDate"
                    type="date"
                    v-model="followUp.dueDate"
                  />
                  <div
                    class="text-danger"
                    :state="!$v.followUp.dueDate.$error"
                    v-if="!$v.followUp.dueDate.required"
                  >
                  Due Date Required!
                  </div>
                </b-form-group>
              </b-colxx>
            </b-row>
            <!--End of Due Date-->

            <!--Assigned To-->
            <b-row>
              <b-col>
                <b-form-group label="Assigned To" class="lg">
                  <multiselect
                    v-model="followUp.assignedTo"
                    :options="employees"
                    :close-on-select="true"
                    placeholder="Select an employee"
                    track-by="id"
                    label="name"
                    :class="{ 'multiselect-is-invalid' : $v.followUp.assignedTo.$error }"
                  >
                  </multiselect>

                  <div
                    class="text-danger"
                    :state="!$v.followUp.assignedTo.$error"
                    v-if="!$v.followUp.assignedTo.required">
                  Assigned To Required!
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <!--End of Assigned To-->
          </b-form>
        </b-card>
      </b-col>
    </b-row>
    <template #modal-footer="{ cancel }">
      <b-button
        size="lg"
        variant="outline-danger"
        class="mr-1"
        @click="(cancel())">
        Cancel
      </b-button>
      <b-button v-if="!$v.$invalid"
        size="lg"
        variant="success"
        class="mr-1"
        @click="saveFollowUp()">
        Save Follow Up
      </b-button>
    </template>
  </b-modal>
</template>
<style lang="scss" scoped>
  .is-invalid {
    border: 1px solid #dc3545 !important;
  }
  .multiselect-is-invalid ::v-deep .multiselect__tags {
    border: 1px solid #dc3545 !important;
  }
  .alignWithLabel{
    width: 100%;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    margin-bottom: 0;
    line-height: 1.5;
  }
</style>

<script>
import { validationMixin } from 'vuelidate'
import Confirmation from '../Common/Confirmation.vue'
import FollowUpMixin from '../../mixins/FollowUpMixin.vue'
import CompanyContactMixin from '../../mixins/CompanyContactMixin.vue'
import EmployeeMixin from '../../mixins/EmployeeMixin.vue'
import Multiselect from 'vue-multiselect'
import moment from 'moment/moment.js'
import { mapMutations } from 'vuex'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import ModalMessages from '../../containers/navs/ModalMessages.vue'

const currencyMask = createNumberMask({
  prefix: '$',
  allowDecimal: false,
  includeThousandsSeparator: true,
  allowNegative: false
})

const { required, maxLength } = require('vuelidate/lib/validators')

export default {
  name: 'followUpModal',
  components: {
    multiselect: Multiselect,
    Confirmation: Confirmation,
    Messages: ModalMessages
  },

  mixins: [FollowUpMixin, validationMixin, CompanyContactMixin, EmployeeMixin],
  validations: {
    followUp: {
      subject: {
        required
      },
      notes: {
        maxLength: maxLength(500)
      },
      dueDate: {
        required
      },
      companyContacts: {
        required
      },
      assignedTo: {
        required
      }
    }
  },
  data: () => ({
    followUpID: 0,
    title: undefined,
    isVisible: false,
    companyContacts: [],
    assignedTo: []
  }),
  async created () {
    this.followUp = {
      subject: '',
      notes: '',
      dueDate: null,
      companyContacts: [],
      assignedTo: [],
      companyID: 0
    }
  },

  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setModalDangerMessage',
      'setModalInfoMessage'
    ]),
    async show (opts = {}) {
      this.title = opts.title

      // Calling the initNew method so that if the user is
      // creating multiple followups quickly and staying on the pageX
      // none of the data will linger in the inputs when the modal is re-opened
      this.initNewFollowUp()

      if (opts.companyID) {
        this.followUp.companyID = opts.companyID
      }

      this.loadCompanyContacts(opts.companyID)
      this.loadEmployees()

      if (opts.exitFunction) {
        this.exitFunction = opts.exitFunction
      }

      this.isVisible = true
    },
    close () {
      if (this.exitFunction) {
        this.exitFunction()
      }

      this.followUpID = 0
      this.setModalDangerMessage('')
      this.setModalInfoMessage('')
      this.isVisible = false
    },
    async loadEmployees () {
      await this.getAllActiveEmployees()
      this.employees = this.mapEmployees()
    },
    mapEmployees () {
      return this.employees.map((r) => {
        return {
          name: `${r.displayName}`,
          id: r.employeeID
        }
      })
    },
    async loadCompanyContacts (companyID) {
      await this.getCompanyContactsByCompanyID(companyID)
      this.companyContacts = this.mapCompanyContacts()
    },
    mapCompanyContacts () {
      return this.companyContacts.map((r) => {
        return {
          displayData: `${r.firstName} ${r.lastName}`,
          contactID: r.contactID,
          firstName: r.firstName,
          lastName: r.lastName
        }
      })
    },

    // add new follow up
    initNewFollowUp (companyID) {
      this.followUp = {
        subject: '',
        notes: '',
        dueDate: null,
        companyContacts: [],
        assignedTo: [],
        companyID: companyID
      }

      this.okButton = 'Send'
    },

    // Had to stringify this so that it creates a copy that won't affect
    // the mask on the model in this component when the mask gets removed for the API
    cancel () {
      this.close()
    },

    async saveFollowUp () {
      this.followUp.companyContacts = this.followUp.companyContacts.map(c => c.contactID)
      this.followUp.assignedTo = this.followUp.assignedTo.id
      this.followUpID = await this.addFollowUp(this.followUp)
      if (this.statusCode === 200) {
        this.close()
        this.setDirtyData(false)
      }
    },
    formatDate (value) {
      if (value === null) {
        return ''
      } else {
        return moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY')
      }
    }
  }
}
</script>
