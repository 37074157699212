<template>
  <div>
    <Confirmation ref="Confirmation"></Confirmation>
    <FollowUpModal ref="FollowUpModal"></FollowUpModal>
    <FollowUpViewModal ref="FollowUpViewModal"></FollowUpViewModal>
    <FollowUpEditModal ref="FollowUpEditModal"></FollowUpEditModal>
    <ActivityModal ref="ActivityModal"></ActivityModal>
    <alert ref="Alert"></alert>
    <datatable-heading
      ref="DatatableHeading"
      :hasTitleSection="false"
      :changePageSize="changePageSize"
      :searchChange="searchChange"
      :from="from"
      :to="to"
      :total="total"
      :perPage="perPage"
      :titleAndButton="true"
      :tableFields="fields"
      @filterChange="filterChange"
    >
      <template v-slot:button>
        <div class="title-and-right-element">
          <PageTitle :title="$t('menu.follow-ups')" :identifier="pageTitleIdentifier"></PageTitle>
          <b-button
            variant="primary"
            size="lg"
            class="top-right-button mr-2"
            @click="addFollowUp()"
          >Add New</b-button>
        </div>
      </template>
    </datatable-heading>

    <b-row>
      <b-colxx xxs="12">
        <b-card>
          <vuetable
            ref="vuetable"
            track-by="followUpId"
            :api-url="apiBase"
            :query-params="makeQueryParams"
            :per-page="perPage"
            :reactive-api-url="false"
            :fields="fields"
            :css="css.table"
            pagination-path
            :row-class="onRowClass"
            @vuetable:pagination-data="onPaginationData"
            @vuetable:row-clicked="rowClicked"
            @vuetable:cell-rightclicked="rightClicked"
            @vuetable:load-error="handleLoadError"
          >
            <template slot="completed" slot-scope="props">
              <div>
                <div v-if='props.rowData.completed === null' style="display:flex;justify-content:center;align-items;center;">
                </div>
                <div v-else style="display:flex;justify-content:center;align-items;center;">
                 <p class="text-muted"> {{ formatDate(props.rowData.completed) }}</p>
                </div>
              </div>
            </template>
          </vuetable>
        </b-card>
        <vuetable-pagination-bootstrap
          class="mt-4"
          ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
        />
      </b-colxx>
    </b-row>

    <v-contextmenu ref="contextmenu">
       <v-contextmenu-item @click="onContextMenuAction('view')">
        <i class="simple-icon-docs" />
        <span>View</span>
      </v-contextmenu-item>
      <v-contextmenu-item @click="onContextMenuAction('edit')">
        <i class="simple-icon-pencil" />
        <span>Edit</span>
      </v-contextmenu-item>
      <v-contextmenu-item v-if='(currentSelectedItem && currentSelectedItem.completed === null)' @click="onContextMenuAction('complete')">
        <i class="simple-icon-check" />
        <span>Complete</span>
      </v-contextmenu-item>
    </v-contextmenu>
  </div>
</template>

<script>
import Confirmation from '../../../../components/Common/Confirmation.vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationBootstrap from '../../../../components/Common/VuetablePaginationBootstrap'
import { BeaconapiUrl } from '../../../../constants/config'
import DatatableHeading from '../../../../containers/datatable/DatatableHeading'
import CssTableConfig from '../../../../constants/VuetableBootstrapconfig'
import FollowUpModal from '../../../../components/Modals/FollowUpModal.vue'
import FollowUpViewModal from '../../../../components/Modals/FollowUpViewModal.vue'
import FollowUpEditModal from '../../../../components/Modals/FollowUpEditModal.vue'
import ActivityModal from '../../../../components/Modals/ActivityModal.vue'
import moment from 'moment/moment.js'
import _ from 'lodash'
import bus from '../../../../main'
import ParentEntityPageTitle from '../../../../components/Common/ParentEntityPageTitle.vue'
import Alert from '../../../../components/Common/Alert.vue'

// so we can set our messages in the layout if needed
import { mapMutations } from 'vuex'
import FollowUpMixin from '../../../../mixins/FollowUpMixin.vue'

import { mutations } from '../../../../store/FilterMenu'

export default {
  props: {
    companyID: {
      type: Number,
      default: 0
    },
    employeeName: {
      type: String,
      default: ''
    },
    isOverdue: {
      type: Boolean,
      default: false
    }
  },

  components: {
    vuetable: Vuetable,
    'vuetable-pagination-bootstrap': VuetablePaginationBootstrap,
    'datatable-heading': DatatableHeading,
    Confirmation: Confirmation,
    FollowUpModal: FollowUpModal,
    FollowUpViewModal: FollowUpViewModal,
    FollowUpEditModal: FollowUpEditModal,
    ActivityModal: ActivityModal,
    PageTitle: ParentEntityPageTitle,
    alert: Alert
  },
  mixins: [FollowUpMixin],
  data () {
    // because the id field is used in other places throughout this page here it should be
    // identified as a variable so that we don't have it hardcoded everywhere
    var _idfield = 'followUpID'

    var tableFields = [
      {
        name: _idfield,
        sortField: _idfield,
        title: 'Follow Up ID',
        titleClass: '',
        dataClass: 'text-muted',
        width: '12%',
        filterType: 'text'
      },
      {
        name: 'subject',
        sortField: 'subject',
        title: 'Subject',
        titleClass: '',
        dataClass: 'text-muted',
        width: '12%',
        filterType: 'text'
      },
      {
        name: 'dueDate',
        sortField: 'dueDate',
        title: 'Due Date',
        titleClass: '',
        dataClass: 'text-muted',
        width: '12%',
        callback: this.formatDate,
        filterType: 'duedate'
      },
      {
        name: 'contacts',
        sortField: 'contacts',
        title: 'Contacts',
        titleClass: '',
        dataClass: 'text-muted',
        width: '12%',
        filterType: 'text'
      },
      {
        name: 'assignedTo',
        sortField: 'assignedTo',
        title: 'Assigned To',
        titleClass: '',
        dataClass: 'text-muted',
        width: '10%',
        filterType: 'text'
      },
      {
        name: '__slot:completed',
        sortField: 'completed',
        title: 'Completed',
        titleClass: 'center aligned text-center',
        dataClass: 'center aligned',
        width: '10%',
        callback: this.formatDate,
        filterType: 'date'
      }
    ]

    // If company ID is 0 that means we did not arrive via company context
    if (this.companyID === 0) {
      // Insert company ID field after the follow up ID
      tableFields.splice(2, 0, {
        name: 'companyID',
        sortField: 'companyID',
        title: 'Company ID',
        titleClass: '',
        dataClass: 'text-muted',
        width: '10%'
      })
    }

    return {
      isLoad: false,
      apiBase: `${BeaconapiUrl}/api/follow-up`,
      sort: '',
      page: 1,
      perPage: 50,
      search: '',
      from: 0,
      to: 0,
      total: 0,
      lastPage: 0,
      items: [],
      selectedItems: [],
      filters: [],
      idfield: _idfield,
      css: CssTableConfig,
      fields: tableFields,
      currentSelectedItem: null
    }
  },

  created () {
    bus.$on('completeFollowUp', (followUpID, companyID) => {
      this.completeFollowUp(followUpID, companyID)
    })
  },
  mounted () {
    if (this.isOverdue) {
      mutations.setFilterByColumn('dueDate', 1)
    }

    if (this.employeeName !== '') {
      mutations.setFilterByColumn('assignedTo', this.employeeName)
    }

    if (this.isOverdue || this.employeeName !== '') {
      this.$refs.DatatableHeading.fireFilterEvent()
    }
  },
  beforeDestroy () {
    bus.$off('completeFollowUp')
  },
  computed: {
    pageTitleIdentifier () {
      return this.companyID === 0 ? null : `Company #${this.companyID}`
    }
  },
  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setAlertMessage',
      'setInfoMessage',
      'appendInfoMessage'
    ]),
    // Vue Table plumbing methods (standardized)

    makeQueryParams (sortOrder, currentPage, perPage) {
      this.selectedItems = []
      return sortOrder[0]
        ? {
          sort: sortOrder[0]
            ? sortOrder[0].field + '|' + sortOrder[0].direction
            : '',
          page: currentPage,
          per_page: this.perPage,
          search: this.search,
          companyID: this.companyID,
          filters: JSON.stringify(this.filters)
        }
        : {
          sort: 'dueDate|desc',
          page: currentPage,
          per_page: this.perPage,
          search: this.search,
          companyID: this.companyID,
          filters: JSON.stringify(this.filters)
        }
    },
    onPaginationData (paginationData) {
      this.from = paginationData.from
      this.to = paginationData.to
      this.total = paginationData.total
      this.lastPage = paginationData.last_page
      this.items = paginationData.data
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    changePageSize (perPage) {
      this.perPage = perPage
      this.refreshVueTable()
    },
    searchChange (val) {
      this.search = val
      this.refreshVueTable()
    },
    handleLoadError (response) {
      this.setDangerMessage(response)
    },
    filterChange (filters) {
      this.filters = filters
      this.refreshVueTable()
    },
    onRowClass (dataItem, index) {
      var outputclass = ''
      if (this.selectedItems.includes(dataItem[this.$data.idfield])) {
        outputclass = 'selected'
      }
      if (this.isPastDue(dataItem.dueDate)) {
        outputclass = outputclass + ' time-row'
      }
      return outputclass
    },
    cellclicked (dataitem, field, event) {
    },
    rightClicked (dataItem, field, event) {
      event.preventDefault()
      this.rightClickedItem = dataItem
      this.currentSelectedItem = dataItem
      const itemId = dataItem[this.$data.idfield]

      if (!this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = [itemId]
      }
      this.$refs.contextmenu.show({ top: event.pageY, left: event.pageX })
      this.selectedItems = this.$refs.vuetable.selectedTo
    },
    getIndex (value, arr, prop) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i][prop] === value) {
          return i
        }
      }
      return -1
    },
    refreshVueTable () {
      this.$refs.vuetable.refresh()
    },
    rowClicked (dataItem, event) {
      const itemId = dataItem[this.$data.idfield]

      if (this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = this.$refs.vuetable.selectedTo.filter(x => x !== itemId)
      } else {
        this.$refs.vuetable.selectedTo.push(itemId)
      }
      this.selectedItems = this.$refs.vuetable.selectedTo
    },

    // End of Standard Vue Table methods

    // Component-specific Vue Table methods

    onContextMenuAction (action) {
      var rightClickedItem = this.rightClickedItem
      var matchedItems = this.$refs.vuetable.tableData.filter(item => this.selectedItems.includes(item.followUpID))
      switch (action) {
        case 'view':
        {
          this.viewFollowUp(rightClickedItem.followUpID)
          break
        }
        case 'edit':
        {
          this.editFollowUp(rightClickedItem.followUpID)
          break
        }
        case 'complete':
          this.completeFollowUp(this.currentSelectedItem.followUpID, this.companyID)
          break
        default:
          break
      }
    },
    // End of Component-specific Vue table methods

    async addFollowUp () {
      if (isNaN(this.companyID) || this.companyID === null || this.companyID === 0) {
        this.setDangerMessage('Please select a Company before adding a Follow Up.')
      } else if (this.companyID && this.companyID > 0) {
        await this.$refs.FollowUpModal.show({
          title: 'Create Follow Up',
          companyID: this.companyID,
          exitFunction: () => {
            this.$refs.vuetable.refresh()
          }
        })
      }
    },
    async viewFollowUp (followUpID) {
      await this.$refs.FollowUpViewModal.show({
        title: 'Follow Up',
        followUpID: followUpID,
        exitFunction: () => {
          this.$refs.vuetable.refresh()
        }
      })
    },
    async editFollowUp (followUpID) {
      await this.$refs.FollowUpEditModal.show({
        title: 'Edit Follow Up',
        followUpID: followUpID,
        exitFunction: () => {
          this.$refs.vuetable.refresh()
        }
      })
    },

    async completeFollowUp (followUpID, companyID) {
      await this.$refs.ActivityModal.show({
        title: 'Add New Activity',
        companyID: companyID,
        followUpID: followUpID,
        exitFunction: () => {
          this.$refs.vuetable.refresh()
        }
      })
    },

    formatDate (value) {
      if (value === null) {
        return ''
      } else {
        return moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY')
      }
    },
    isPastDue (date) {
      const today = new Date()
      const dueDate = new Date(date)
      if ((dueDate.setHours(0, 0, 0, 0) - today.setHours(0, 0, 0, 0)) >= 0) {
        return false
      }
      return true
    }
  }
}
</script>
