<template>
  <b-modal
    id="modalbasic"
    v-b-modal.modal-center
    ref="modalbasic"
    @hide="close()"
    v-model="isVisible"
    :title="this.title"
    @cancel="close()"
    @close="close()"
    :no-close-on-backdrop="true"
  >
    <Messages />
    <b-row>
      <b-col>
        <b-card sm="12">
          <Confirmation ref="Confirmation"></Confirmation>
          <b-form>
            <b-row>
              <b-col>
                <p>Follow Up ID: {{ this.followUp.followUpID }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Subject" class="lg">
                  <b-form-input
                    id="subject"
                    placeholder="Subject"
                    class="col-sm-6"
                    v-model.trim="$v.followUp.subject.$model"
                    :state="!$v.followUp.subject.$error"
                  >
                  </b-form-input>
                  <div
                    class="text-danger"
                    :state="!$v.followUp.subject.$error"
                    v-if="!$v.followUp.subject.required"
                  >
                  Subject is required!
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Notes" class="lg">
                  <b-form-textarea
                    id="notes"
                    placeholder="Notes"
                    class="col-sm-10"
                    rows="3"
                    v-model.trim="followUp.notes"
                    :state="!$v.followUp.notes.$error"
                  ></b-form-textarea>
                  <div
                    class="text-danger"
                    :state="!$v.followUp.notes.$error"
                    v-if="!$v.followUp.notes.maxLength"
                  >
                  Maximum Characters Allowed 500!
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Company Contacts" class="lg">
                  <Multiselect
                    id="companyContacts"
                    v-model="followUp.companyContacts"
                    :options="companyContacts"
                    :multiple="true"
                    :close-on-select="false"
                    placeholder="Select Contacts"
                    label="displayData"
                    track-by="contactID"
                  >
                  </Multiselect>
                  <div
                    class="text-danger"
                    :state="!$v.followUp.companyContacts.$error"
                    v-if="!$v.followUp.companyContacts.required"
                  >
                  Contacts Required!
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-colxx lg="6">
                <b-form-group label="Due Date" class="lg">
                  <b-form-input
                    id="dueDate"
                    type="date"
                    class="col-sm-10"
                    v-model="followUp.dueDate"
                  />
                  <div
                    class="text-danger"
                    :state="!$v.followUp.dueDate.$error"
                    v-if="!$v.followUp.dueDate.required"
                  >
                  Due Date Required!
                  </div>
                </b-form-group>
              </b-colxx>
            </b-row>
            <b-row>
              <b-col lg="6" md="6">
                <b-form-group label="Created Date" class="lg">
                  <b-form-input
                    disabled
                    type="date"
                    class="col-sm-10"
                    v-model="followUp.dateCreated"
                  />
                </b-form-group>
              </b-col>
              <b-col  lg="6"  md="6">
                <b-form-group label="Created By" class="lg">
                  <b-form-input
                    disabled
                    placeholder="Created By"
                    class="col-sm-10"
                    v-model="followUp.createdBy.displayName"
                  />
                </b-form-group>
              </b-col>
            </b-row>
             <b-row>
              <b-col lg="6" md="6">
                <b-form-group label="Last Modified Date" class="lg">
                  <b-form-input
                    disabled
                    type="date"
                    class="col-sm-10"
                    v-model="followUp.lastModifiedDate"
                  />
                </b-form-group>
              </b-col>
              <b-col  lg="6" md="6">
                <b-form-group label="Last Modified By" class="lg">
                  <b-form-input
                    disabled
                    placeholder="Created By"
                    class="col-sm-10"
                    v-model="followUp.lastModifiedBy"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Assigned To" class="lg">
                  <Multiselect
                    v-model="followUp.assignedTo"
                    :options="this.employees"
                    :close-on-select="true"
                    placeholder="Select an Employee"
                    :multiple="false"
                    id="assignedTo"
                    track-by="employeeID"
                    label="displayName"
                    :class="{ 'multiselect-is-invalid' : $v.followUp.assignedTo.$error }"
                  >
                  </Multiselect>
                  <div
                    class="text-danger"
                    :state="!$v.followUp.assignedTo.$error"
                    v-if="!$v.followUp.assignedTo.required">
                  Assigned To Required!
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
    <template #modal-footer="{ cancel }">
      <b-button
        size="lg"
        variant="outline-danger"
        class="mr-1"
        @click="(cancel())">
        Cancel
      </b-button>
      <div style="display:flex;justify-content:center;align-items;center;">
        <b-button
          :disabled="$v.followUp.$invalid"
          size="lg"
          @click="saveFollowUp()"
        >Save Follow Up
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<style lang="scss" scoped>
  .is-invalid {
    border: 1px solid #dc3545 !important;
  }
  .alignWithLabel{
    width: 100%;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    margin-bottom: 0;
    line-height: 1.5;
  }
  .multiselect-is-invalid ::v-deep .multiselect__tags {
    border: 1px solid #dc3545 !important;
  }
</style>
<script>
import { validationMixin } from 'vuelidate'
import Confirmation from '../Common/Confirmation.vue'
import FollowUpMixin from '../../mixins/FollowUpMixin.vue'
import CompanyContactMixin from '../../mixins/CompanyContactMixin.vue'
import moment from 'moment/moment.js'
import { mapMutations } from 'vuex'
import ModalMessages from '../../containers/navs/ModalMessages.vue'
import Multiselect from 'vue-multiselect'
import EmployeeMixin from '../../mixins/EmployeeMixin.vue'

const { required, maxLength } = require('vuelidate/lib/validators')

export default {
  components: {
    Confirmation: Confirmation,
    Messages: ModalMessages,
    Multiselect: Multiselect
  },
  mixins: [FollowUpMixin, validationMixin, CompanyContactMixin, EmployeeMixin],
  validations: {
    followUp: {
      subject: {
        required
      },
      notes: {
        maxLength: maxLength(500)
      },
      dueDate: {
        required
      },
      companyContacts: {
        required
      },
      assignedTo: {
        required
      }
    }
  },
  data: () => ({
    title: undefined,
    isVisible: false,
    companyContactsSelected: [],
    assignedTo: []

  }),

  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setModalDangerMessage',
      'setModalInfoMessage'
    ]),
    async show (opts = {}) {
      this.title = opts.title

      if (opts.followUpID) {
        this.followUp.followUpID = opts.followUpID
        await this.getFollowUpForEditModalByID(opts.followUpID)
        this.followUp.dueDate = moment(this.followUp.dueDate).format('yyyy-MM-DD')
        this.followUp.completedDate = moment(this.followUp.completedDate).format('yyyy-MM-DD')
        this.followUp.dateCreated = moment(this.followUp.dateCreated).format('yyyy-MM-DD')
        this.followUp.lastModifiedDate = moment(this.followUp.lastModifiedDate).format('yyyy-MM-DD')
      }

      this.loadCompanyContacts(this.followUp.companyID)

      this.getAllActiveEmployees()

      if (opts.exitFunction) {
        this.exitFunction = opts.exitFunction
      }
      this.followUp.companyContacts = this.mapExistingCompanyContacts()
      this.isVisible = true
    },
    close () {
      if (this.exitFunction) {
        this.exitFunction()
      }

      this.setModalDangerMessage('')
      this.setModalInfoMessage('')
      this.isVisible = false
    },
    cancel () {
      this.close()
    },
    async saveFollowUp () {
      this.followUpID = await this.updateFollowUp(this.followUp)
      if (this.statusCode === 200) {
        this.close()
      }
    },
    async loadCompanyContacts (companyID) {
      await this.getCompanyContactsByCompanyID(companyID)
      this.companyContacts = this.mapCompanyContacts()
    },
    mapCompanyContacts () {
      return this.companyContacts.map((r) => {
        return {
          displayData: `${r.firstName} ${r.lastName}`,
          contactID: r.contactID,
          firstName: r.firstName,
          lastName: r.lastName
        }
      })
    },
    mapExistingCompanyContacts () {
      return this.followUp.companyContacts.map((r) => {
        return {
          displayData: `${r.firstName} ${r.lastName}`,
          contactID: r.contactID,
          firstName: r.firstName,
          lastName: r.lastName
        }
      })
    }

  }
}
</script>
